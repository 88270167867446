import {ErrorHandler, Injectable} from '@angular/core'
import {ProblemService} from '../services/problem.service'
import { HttpErrorResponse } from '@angular/common/http'
import {MatDialog} from '@angular/material/dialog'
import {ConflictDialogComponent} from '../dialogs/conflict-dialog/conflict-dialog.component'
import {AutoSaveService} from '../services/auto-save.service'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private problemService: ProblemService,
    private autoSaveService: AutoSaveService,
    private dialog: MatDialog
  ) {
  }

  handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status === 409) {
      this.autoSaveService.saving$.next(false)
      this.autoSaveService.changed$.next(false)
      this.dialog.open(ConflictDialogComponent, {
        disableClose: true
      })
    } else {
      this.problemService.problems$.next({
        description: 'Unhandled error',
        handled: false,
        error
      })
      // IMPORTANT: Rethrow the error otherwise it gets swallowed
      throw error
    }
  }
}
