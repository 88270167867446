import {
  HttpErrorResponse, HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest
} from '@angular/common/http'
import {inject} from '@angular/core'
import {NEVER, Observable, throwError} from 'rxjs'
import {catchError, map, switchMap} from 'rxjs/operators'
import {AuthService, IAuthInfo} from '../services/auth.service'


/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
export const responseInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>, next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService: AuthService = inject(AuthService)

  return next(req)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && req.url.indexOf('users/self') === -1) {
          return authService.checkLogin().pipe(
            switchMap((res: boolean) => {
              if (res === false) {
                // No user we have to go to login, we do not report this
                // error back, we just let it run until the user is logged in again
                // if the user gets back logged in the login page should handle that.
                return NEVER
                // return throwError(() => error)
              }
              return authService.authState$
            }),
            map((auth: IAuthInfo) => req.clone({
              headers: req.headers.set('Authorization', `Bearer ${auth.accessToken}`)
            })),
            switchMap((authReq: HttpRequest<any>) => next(authReq))
          )
        }
        return throwError(() => error)
      })
    )
}
