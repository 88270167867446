import {Component} from '@angular/core'

@Component({
  selector: 'kdl-root',
  template: `
    <kdl-header class="no-print"></kdl-header>
    <div class="home">
      <router-outlet></router-outlet>
    </div>
    <div class="filler"></div>
    <kdl-footer class="no-print"></kdl-footer>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
}
