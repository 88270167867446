import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest
} from '@angular/common/http'
import {inject} from '@angular/core'
import {NEVER, Observable} from 'rxjs'
import {AuthService, IAuthInfo} from '../services/auth.service'

/**
 * Inject to set auth header on all requests.
 */
export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>, next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService: AuthService = inject(AuthService)
  let accessToken: string | null = null

  authService.authState$.subscribe({
    next: (state: IAuthInfo) => accessToken = state.accessToken
  })

  if (req.url.indexOf('-kulladal-images.s3') !== -1) {
    return next(req)
  }

  if (!accessToken) {
    return NEVER // next.handle(req)
  }

  const authReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
  })

  return next(authReq)
}
