import {Injectable} from '@angular/core'
import {AuthService, IAuthInfo} from './auth.service'
import {filter, switchMap} from 'rxjs/operators'
import {EAuthState} from '../common/interface/auth'
import {SettingsItemService} from './settings-item.service'

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  constructor(
    private authService: AuthService,
    private settingsItemService: SettingsItemService
  ) {
    this.authService.authState$.pipe(
      filter((authState: IAuthInfo) => authState.state === EAuthState.authenticated),
      // Here we must bootstrap the options
      switchMap(() => this.settingsItemService.bootstrap())
    ).subscribe()
  }
}
