import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {
  ADMIN_HOME_PATH,
  CUSTOMER_HOME_PATH,
  CUSTOMER_INTERNAL_HOME_PATH,
  EDIT_PROJECT_HOME_PATH,
  FACTORY_INTERNAL_HOME_PATH,
  LOGIN_HOME_PATH,
  PRODBOARD_HOME_PATH,
  PRODUCT_HOME_PATH,
  SETTINGS_HOME_PATH,
  USER_HOME_PATH
} from './common/interface/routing'
import {authGuard} from './application/auth.guard'

/**
 * All routes for the app. Add routes here to add them globally
 */
export const routes: Routes = [
  {
    path: PRODUCT_HOME_PATH,
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
    canActivate: [authGuard],
    title: 'Mill - Produkter'
  },
  {
    path: CUSTOMER_INTERNAL_HOME_PATH,
    loadChildren: () => import('./customer-internal/customer-internal.module').then(m => m.CustomerInternalModule),
    canActivate: [authGuard]
  },
  {
    path: FACTORY_INTERNAL_HOME_PATH,
    loadChildren: () => import('./factory-internal/factory-internal.module').then(m => m.FactoryInternalModule),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_HOME_PATH,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    title: 'Mill - Kulladal'
  },
  {
    path: USER_HOME_PATH,
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [authGuard],
    title: 'Mill - Användare'
  },
  {
    path: ADMIN_HOME_PATH,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [authGuard],
    title: 'Mill - Kulladal'
  },
  {
    path: SETTINGS_HOME_PATH,
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [authGuard],
    title: 'Mill - Inställningar'
  },
  {
    path: EDIT_PROJECT_HOME_PATH,
    loadChildren: () => import('./edit-project/edit-project.module').then(m => m.EditProjectModule),
    canActivate: [authGuard]
  },
  {
    path: CUSTOMER_HOME_PATH,
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
    canActivate: [authGuard],
    title: 'Mill - Kundprojekt'
  },
  {
    path: PRODBOARD_HOME_PATH,
    loadChildren: () => import('./prodboard/prodboard.module').then(m => m.ProdboardModule),
    canActivate: [authGuard],
    title: 'Mill - Kundprojekt'
  },
  {
    path: '**',
    redirectTo: ADMIN_HOME_PATH,
    pathMatch: 'full',
    title: 'Mill - Kulladal'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
